import {useState, useEffect} from 'react'
import compact from 'lodash/compact'
import {useField} from 'react-final-form'

import {Flex, Grid, Box, PositionContainer} from 'src/components/Box'
import {Body, SmallBody} from 'src/components/text'
import Modal from 'src/components/Modal'
import Search, {SearchResultItem, RenderProps} from 'src/components/Search'
import {useSearchSchoolQuery, School, Maybe, State} from 'src/graphql-generated'
import {Action} from 'src/components/Action'
import {required} from 'src/form/validation'

import Loading from '../Loading'
import {CaretLeftIcon} from '../icons'
import {BasicRoundedTextField} from './RoundedTextField'

type SchoolItem = Pick<School, 'id' | 'name' | 'city' | 'state'>

const SearchResults: React.FunctionComponent<
  RenderProps<SchoolItem> & {state: Maybe<State>}
> = ({searchValue, getItemProps, state}) => {
  const {data, loading, error} = useSearchSchoolQuery({
    variables: {
      filter: {
        name: {cont: searchValue},
        state: {eq: state},
      },
      limit: 50,
    },
  })

  if (loading) return <Loading size="small" mt="4" />
  if (error) return <Box px="2">Something went wrong...</Box>

  const schools = data?.schools ? compact(data.schools.nodes) : []
  if (schools.length === 0) return <Box px="2">No Search Results</Box>

  return (
    <Box css={{maxHeight: '300px', overflowY: 'auto'}}>
      {schools.map((school) => (
        <SearchResultItem {...getItemProps({key: school.id, item: school})}>
          <Flex
            css={(theme) => ({
              '&:hover': {backgroundColor: theme.colors.grey1},
            })}
            px="2"
            flexDirection="column"
          >
            {school.name}
            <SmallBody color="grey4">
              {school.city}, {school.state}
            </SmallBody>
          </Flex>
        </SearchResultItem>
      ))}
    </Box>
  )
}

interface Props {
  name: string
  label: string
  state: string | null
}

const SchoolSelect = ({name, label, state}: Props) => {
  const {input, meta} = useField(name, {
    validate: required,
  })

  const [showPicker, setShowPicker] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState<Maybe<SchoolItem>>(null)

  // Reset school when state changes
  useEffect(() => {
    setSelectedSchool(null)
    input.onChange(null) // Reset the field in final-form
  }, [state])

  return (
    <>
      <PositionContainer position="relative">
        <Action
          onClick={() => {
            if (state) setShowPicker(true)
          }}
          type="button"
          as="div"
          data-cy={`edit-${name}`}
          aria-label="Edit"
          css={{marginTop: '10px'}}
        >
          <BasicRoundedTextField
            label={label}
            inputProps={{
              value: selectedSchool?.name || '',
              type: 'text',
              readOnly: true,
              id: name,
              name: name,
              disabled: !state, // Disable if state is not selected
            }}
            labelProps={{htmlFor: name}}
            linkify
          />
        </Action>
        {meta.touched && meta.error && state && (
          <SmallBody color="red">{meta.error}</SmallBody>
        )}
      </PositionContainer>

      {showPicker && state && (
        <Modal isOpen={showPicker} onRequestClose={() => setShowPicker(false)}>
          <Grid width="100%" height="100%" gridTemplateRows="auto 1fr">
            <Action onClick={() => setShowPicker(false)}>
              <Flex p="2" alignItems="center">
                <CaretLeftIcon />
                <Body fontWeight="semiBold">Back</Body>
              </Flex>
            </Action>
            <Search<SchoolItem>
              placeholder="Search by school name..."
              id="school-search"
              onSelect={(school) => {
                setSelectedSchool(school)
                input.onChange(school.id) // Update form state
                setShowPicker(false)
              }}
            >
              {(props) => (
                <SearchResults {...props} state={state as Maybe<State>} />
              )}
            </Search>
          </Grid>
        </Modal>
      )}
    </>
  )
}

export default SchoolSelect
